import { Container, useMediaQuery } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom";
import logo from "assets/logo.svg";
import { useState, useEffect } from "react";
import {TradeBtn} from "./styles";
import {GET_STARTED_LINK} from "../../pages/Homepage/const";

const Header = function () {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down(1120));

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        px: isSmallScreen
          ? "20px!important"
          : isMediumScreen
          ? "50px!important"
          : "24px",
        py: "20px",
        position: "fixed",
        top: 0,
        backdropFilter: "blur(10px)",
        zIndex: 3,
        borderBottom: scrollPosition
          ? "1px solid #28283b"
          : "1px solid #030303",
        background: scrollPosition ? "#12121d4d" : "none",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            width: "90%",
            height: "256px",
            background:
              "radial-gradient(50% 50% at 50% 50%, rgba(133, 104, 220, 0.38) 0%, rgba(171, 171, 171, 0) 100%)",
            overflow: "hidden",
            position: "absolute",
            top: "-210px",
            zIndex: -1,
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              width: isSmallScreen
                ? "154px"
                : isMediumScreen
                ? "161px"
                : "208px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => navigate("/")}
            component="img"
            src={logo}
          />
            <TradeBtn style={{
                width: isSmallScreen ? 119 : 205,
                fontSize: isSmallScreen ? 12 : 18,
                height: isSmallScreen ? 35 : 45
            }} variant="contained" href={GET_STARTED_LINK}>
                Get Started
            </TradeBtn>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;

import {Box, useTheme} from "@mui/system"

import {
    StyledIconWrapper,
    StyledListItem,
    StyledListItemDesc,
    StyledListItemHeader,
    StyledListItemTitle
} from "../styles"
import {useMediaQuery} from "@mui/material";

export const ListItem = ({ listItem }) => {
    const theme = useTheme()

    const isSmallScreen = useMediaQuery(theme.breakpoints.down(470))

    return (
        <StyledListItem>
            <StyledListItemHeader>
                <StyledIconWrapper style={{
                    width: isSmallScreen ? 25 : 45,
                    height: isSmallScreen ? 25 : 45,
                }}>
                    <Box
                        component="img"
                        src={listItem.icon}
                        width={isSmallScreen ? 10 : 20}
                        height={isSmallScreen ? 10 : 20}
                    />
                </StyledIconWrapper>
            </StyledListItemHeader>
            <div>
                <StyledListItemTitle style={{
                    fontSize: isSmallScreen ? 10 : 16
                }}>
                    {listItem.title}
                </StyledListItemTitle>
                <StyledListItemDesc style={{
                    fontSize: isSmallScreen ? 8 : 14
                }}>
                    {listItem.desc}
                </StyledListItemDesc>
            </div>
        </StyledListItem>
    )
}

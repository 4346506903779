import { Button, Container, Typography, Link } from "@mui/material"
import { Box, useTheme } from "@mui/system"
import { useMediaQuery } from "@mui/material"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import tradingAppImgPng from "assets/tradingApp.png"
import joinAlphaNow from "assets/joinAlphaNow.png"
import {
    GradientText,
    StyledBackgroundImage,
    StyledBodyContent,
    StyledBodyTitles,
    StyledButton,
    StyledButtonGetStarted,
    StyledContent,
    StyledDescription,
    StyledDescriptionText,
    StyledTitle
} from "./styles"
import {ALPHA_NFT_LINK, GET_STARTED_LINK, roadmapList} from "./const"
import { ListItem } from "./components/ListItem"

gsap.registerPlugin(ScrollTrigger)

const Homepage = function () {
    const theme = useTheme()

    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
    const isVerySmallScreen = useMediaQuery(theme.breakpoints.down(470))
    const isMediumScreen = useMediaQuery(theme.breakpoints.down(1120))
    const isChangeLayoutScreen = useMediaQuery(theme.breakpoints.down(1020))

    return (
        <Container
            maxWidth="lg"
            sx={{
                px: isSmallScreen
                    ? "20px!important"
                    : isMediumScreen
                        ? "50px!important"
                        : "24px",
                mt: isSmallScreen ? "85px" : "100px",
            }}
        >
            <Box
                sx={{
                    pt: isSmallScreen ? "calc((100vh - 518px) / 4)" : "24px",
                }}
            >
                <StyledContent style={{
                    marginBottom: isSmallScreen ? "0px" : "35px",
                    flexDirection: isChangeLayoutScreen ? "column" : "row",
                }}>
                    <Box
                        style={{
                            maxWidth: isSmallScreen
                                ? "inherit"
                                : isMediumScreen
                                    ? "525px"
                                    : "623px",
                            textAlign: isSmallScreen ? "center" : isChangeLayoutScreen ? "center" : "left",
                        }}
                    >
                        <GradientText sx={{ pt: "15px", pb: "10px" }}>
                            TESTNET NOW LIVE
                        </GradientText>
                        <StyledTitle style={{
                            fontSize: isSmallScreen
                                ? "30px"
                                : isMediumScreen
                                    ? "40px"
                                    : "55px",
                            lineHeight: isSmallScreen ? "40px" : "70px",
                        }}>
                            Perpetuals Decentralized
                        </StyledTitle>
                        <StyledDescription style={{
                            display: isSmallScreen ? 'grid' : 'block',
                        }}>
                            <StyledDescriptionText
                                style={{
                                    fontSize: isSmallScreen ? "13px" : "16px",
                                    paddingBottom: isSmallScreen ? "30px" : "40px",
                                    width: isSmallScreen ? "50%" : isChangeLayoutScreen ? "100%" : "60%",
                                    textAlign: isChangeLayoutScreen ? "center" : "left",
                                }}
                            >
                                Trade perpetuals contracts with up to <b>10x leverage</b> and{" "}
                                <b>get rewards</b>.
                            </StyledDescriptionText>
                        </StyledDescription>
                        <StyledButton
                            href={GET_STARTED_LINK}
                            variant="contained"
                            sx={{
                                marginBottom: "20px",
                                padding: "12px 24px",
                                fontSize: isSmallScreen ? "13px" : "16px",
                                width: isSmallScreen ? 150 : 200,
                            }}
                        >
                            Get Started
                        </StyledButton>
                        <Button
                            href={ALPHA_NFT_LINK}
                            variant="outlined"
                            color="inherit"
                            sx={{
                                marginBottom: "20px",
                                marginLeft: isSmallScreen ? "10px" : "20px",
                                padding: "11px 21px",
                                borderRadius: "24px",
                                fontSize: isSmallScreen ? "13px" : "16px",
                                width: isSmallScreen ? 150 : 180,
                            }}
                        >
                            Get Alpha NFT
                        </Button>
                        <Link href={ALPHA_NFT_LINK}>
                            <Box
                                sx={{
                                    marginTop: isSmallScreen ? "10px" : "30px",
                                }}
                            >
                                <Box
                                    component="img"
                                    src={joinAlphaNow}
                                    alt="join Alpha Now"
                                    width={isSmallScreen ? "335px" : "454px"}
                                />
                            </Box>
                        </Link>
                    </Box>
                    <StyledBackgroundImage style={{
                        backgroundImage: `url(${tradingAppImgPng})`,
                        left: isChangeLayoutScreen ? '10%' : '50%',
                        top: isVerySmallScreen ? '50%' : isSmallScreen ? '40%' : isChangeLayoutScreen ? '45%' : 100,
                        height: isVerySmallScreen ? 490 : 698
                    }} />
                    <StyledBodyContent
                        style={{
                            marginRight: isChangeLayoutScreen ? 0 : 80,
                            marginTop: isChangeLayoutScreen ? 93 : 0,
                            maxWidth: isVerySmallScreen ? 286 : 416,
                            minHeight: isVerySmallScreen ? 290 : 586,
                        }}
                    >
                        <div style={{
                            paddingLeft: 20,
                            paddingRight: 20
                        }}>
                            <StyledBodyTitles style={{
                                margin: isVerySmallScreen ? 25 : 50
                            }}>
                                <Typography
                                    sx={{
                                        fontSize: isVerySmallScreen ? "10px" : "18px",
                                        color: "white",
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Welcome to Palmswap
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: isVerySmallScreen ? "8px" : "14px",
                                        color: "#787878",
                                    }}
                                >
                                    Start your perpetual contract experience
                                    with us on the BNB smart chain now.
                                </Typography>
                            </StyledBodyTitles>
                            {roadmapList.map((listItem, i) => (
                                <ListItem listItem={listItem} key={i} />
                            ))}
                        </div>
                        <div>
                            <StyledButtonGetStarted
                                variant="contained"
                                href={GET_STARTED_LINK}
                                style={{
                                    marginTop: 25,
                                    width: isVerySmallScreen ? 186 : 316,
                                    fontSize: isVerySmallScreen ? "10px" : "13px",
                                    height: isVerySmallScreen ? 30 : 45,
                                }}
                            >
                                Get Started
                            </StyledButtonGetStarted>
                        </div>
                    </StyledBodyContent>
                </StyledContent>
            </Box>
        </Container>
    )
}

export default Homepage

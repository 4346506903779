import {styled, Typography} from "@mui/material"

export const StyledFooter = styled("div")({
    marginTop: 150,
    marginBottom: 18,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-between'
})

export const StyledFooterTitle = styled(Typography)({
    overflow: 'hidden',
    fontWeight: 300,
    fontStyle: 'normal',
    fontFamily: 'Poppins, serif',
    color: '#787878',
    letterSpacing: 0,
    lineHeight: 1.6,
})

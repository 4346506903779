import {StyledFooter, StyledFooterTitle} from "./styles";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/system";

export const Footer = () => {
    const theme = useTheme()

    const isSmallScreen = useMediaQuery(theme.breakpoints.down(490))

    const currentYear = new Date().getFullYear()

    return (
        <StyledFooter style={{
            marginRight: isSmallScreen ? 10 : '10%',
            marginLeft: isSmallScreen ? 10 : '10%',
        }}>
            <StyledFooterTitle style={{ marginTop: isSmallScreen ? 20 : 0, fontSize: isSmallScreen ? 12 : 14 }}>
                Copyright © {currentYear} Palmswap.org.
            </StyledFooterTitle>
            <StyledFooterTitle style={{ marginTop: isSmallScreen ? 20 : 0, fontSize: isSmallScreen ? 12 : 14 }}>
                All rights reserved.
            </StyledFooterTitle>
        </StyledFooter>
    )
}

import styled from "@emotion/styled";
import Button from "@mui/material/Button";

export const TradeBtn = styled(Button)`
  border: none;
  padding: 12px 32px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 24px;
  width: 160px;
  text-alignment: center;
  white-space: nowrap;
`;

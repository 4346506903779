import {Button, styled, Typography } from "@mui/material";
import {Box} from "@mui/system";
import {StyleContentProps} from "./interface";

export const StyledButton = styled(Button)`
  border: none;
  padding: 8px 24px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 24px;
`

export const StyledBackgroundImage = styled('div')`
  width: 1319px;
  -webkit-filter: blur(8px);
  filter: blur(8px);
  overflow: hidden;
  aspect-ratio: 1.7777777777777777 / 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 40px;
  position: absolute;
  z-index: 0;
`

export const GradientText = styled(Typography)`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 8px;
  font-weight: 600;
  background: linear-gradient(to right, #9d7be9 0%, #6f57d1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  display: inline-block;
`

export const StyledContent = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "url('assets/gridBackground.png')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "auto",
})

export const StyledDescription = styled(Box)({
    placeItems: 'center'
})

export const StyledTitle = styled(Typography)({
    letterSpacing: "3px",
    fontFamily: "MazzardH-ExtraBold",
    fontWeight: "700",
    textTransform: "uppercase",
    paddingBottom: 5
})

export const StyledDescriptionText = styled(Typography)({
    lineHeight: "24px",
    color: "#787878",
})

export const StyledButtonGetStarted = styled(Button)({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 10px 0px 10px',
    backgroundColor: 'var(--Main, #735bd3)',
    overflow: 'visible',
    alignContent: 'center',
    flexWrap: 'nowrap',
    gap: 10,
    borderRadius: 7,
    marginRight: 50,
    marginLeft: 50,
    marginBottom: 30,
    fontSize: 13,
})

export const StyledBodyContent = styled("div")({
    boxSizing: 'border-box',
    boxShadow: '0px 0px 6px 2px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#181b1f',
    overflow: 'visible',
    borderRadius: 20,
    border: '1px solid var(--Border_Color, #23252b)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 1,
})

export const StyledBodyTitles = styled("div")({
    display: 'grid',
    placeItems: 'center',
})

export const StyledListItem = styled("div")({
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 30,
    marginRight: 30
})

export const StyledListItemHeader = styled("div")({
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 20,
    marginRight: 20
})

export const StyledIconWrapper = styled("div")({
    backgroundColor: '#212327',
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

export const StyledListItemTitle = styled(Typography)({
    color: "white",
    fontWeight: 'bold'
})

export const StyledListItemDesc = styled(Typography)({
    color: "#6C7284",
})
